import React, { useState } from 'react';
import HubspotForm from 'react-hubspot-form';

import LoadingAnimation from '../ui/LoadingAnimation';

export default function RegisterForm() {
  const [isLoading, setIsLoading] = useState(true);

  const loadingContent = (
    <div className="py-40 md:py-40 md:px-10 px-mb-10">
      <LoadingAnimation />
      <p className="mt-20 text-xs text-center text-gray-400 font-sans">
        If the content does not appear correctly, <br />
        follow this{' '}
        <a href="https://info.polkadot.network/pioneers-prize" target="_blank" rel="noopener noreferrer">
          link
        </a>
        .
      </p>
    </div>
  );

  return (
    <>
      {!isLoading && <h1 className="font-bold text-center">Register</h1>}
      <div id="hubspotForm">
        <HubspotForm
          portalId="7051618"
          formId="6f906b84-eee6-4326-b52c-e6d50a62b65a"
          onSubmit={() => console.log('Submit!')}
          onReady={() => setIsLoading(false)}
          loading={loadingContent}
        />
      </div>
    </>
  );
}
