import React from 'react';

import Logo from '../../images/svgs/logo.svg';

const Header = props => {
  return (
    <header className="header">
      <div className="header__body">
        <Logo className="logo logo--header" />
        {!props.is404 && (
          <button
            className={props.isModalOpen ? 'button button--hidden' : 'button'}
            onClick={() => props.onModalOpen()}
          >
            Get in touch
          </button>
        )}
      </div>
    </header>
  );
};

export default Header;
