import React from 'react';

import Logo from '../../images/svgs/logo.svg';

const Footer = props => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={props.is404 ? 'footer footer--404' : 'footer'} id="footer">
      <div className="footer__body">
        {!props.is404 && <Logo className="logo logo--footer" />}
        <span>© {currentYear}</span>
        <ul className="footer-navigation">
          <li className="footer-navigation__item">
            <a className="footer-navigation__link" href="https://web3.foundation/" target="_blank" rel="noreferrer">
              Web3 Foundation
            </a>
          </li>
          <li className="footer-navigation__item">
            <a
              className="footer-navigation__link"
              href="https://polkadot.network/legal-disclosures/"
              target="_blank"
              rel="noreferrer"
            >
              Legal Disclosures
            </a>
          </li>
          <li className="footer-navigation__item">
            <a
              className="footer-navigation__link"
              href="https://polkadot.network/disclaimer/"
              target="_blank"
              rel="noreferrer"
            >
              Disclaimer
            </a>
          </li>
          <li className="footer-navigation__item">
            <a
              className="footer-navigation__link"
              href="https://polkadot.network/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              Privacy
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
