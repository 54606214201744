import React from 'react';

import Footer from './Footer';
import Header from './Header';
import SignupModal from './SignupModal';

export default function Layout(props) {
  return (
    <>
      <main id="main" className={props.is404 ? 'main main--404' : 'main'}>
        <Header isModalOpen={props.isModalOpen} onModalOpen={props.onModalOpen} is404={props.is404} />
        {props.children}
        <Footer is404={props.is404} />
      </main>
      <SignupModal isModalOpen={props.isModalOpen} onModalClose={props.onModalClose} />
    </>
  );
}
