import React from 'react';

import Logo from '../../images/svgs/logo.svg';
import RegisterForm from './RegisterForm';

const SignupModal = props => {
  return (
    <div className={props.isModalOpen ? 'modal modal--active' : 'modal'}>
      <div className="modal__body">
        <Logo className="logo logo--modal" />
        <a className="modal__close" onClick={() => props.onModalClose()}>
          Close
        </a>
        <div className="modal__form">
          <RegisterForm />
        </div>
      </div>
    </div>
  );
};

export default SignupModal;
